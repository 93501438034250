<template>
  <div
    v-if="canVisibleCookiePopup"
    class="fixed inset-x-12 bottom-12 z-50 items-center rounded-8 bg-[black]/80 p-16 text-14 text-white md:inset-x-24 md:bottom-24 md:flex"
  >
    <div class="mb-12 flex-1 md:mb-0">
      <i18n-t
        keypath="generate.cookie.text"
        tag="p"
        scope="global"
      >
        <template #link>
          <a
            :href="docLink"
            target="_blank"
            class="!text-white underline"
          >
            {{ $t('generate.cookie.linkText') }}
          </a>
        </template>
      </i18n-t>
    </div>
    <div class="flex items-center justify-end gap-8 pl-16">
      <Button
        variant="outline"
        class="h-24 !min-h-[auto] !text-12 md:!text-14"
        @click="setStatus(false)"
      >
        {{ $t('generate.cookie.reject') }}
      </Button>
      <Button
        variant="outline"
        class="h-24 !min-h-[auto] !text-12 md:!text-14"
        @click="setStatus(true)"
      >
        {{ $t('generate.cookie.allow') }}
      </Button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Button from '@shared/components/button/index.vue';
import { GTM, GA4, Integration } from '@/utils/integrations';
import ConsentCountries from './consentCountries';
import { useSiteData } from '@/composables/useSiteData';
import { useDocLinks } from '@/composables/useDocLinks';
import { DOCS } from '@/shared/types';
import { COOKIE } from '@/utils/cookie';

const { siteData } = useSiteData();

const date = new Date();
date.setFullYear(date.getFullYear() + 1);

let domain = ""
if (import.meta.client) {
  domain = window.location.hostname.split(".").slice(-2).join(".");
}
const cookieConsent = useCookie(COOKIE.COOKIE_CONSENT, {
  secure: true,
  sameSite: 'strict',
  expires: date,
  domain: `.${domain}`
});

const showCookie = useCookie(COOKIE.SHOW_COOKIE);

const compareShowCookieAndConsent = computed(() => {
  return (
    showCookie.value === null || showCookie.value === undefined
      ? (cookieConsent.value === undefined || showCookie.value === null)
      : (showCookie.value && !cookieConsent.value)
  );
});

const showCookiePopup = ref(false);
const isAvailableCountry = ref(false);
const docLinks = computed(() => {
  const locale = siteData.value?.lang === 'tr' ? 'tr' : 'en';
  return useDocLinks()[locale] as Record<DOCS, string>;
})
const docLink = ref(docLinks.value[DOCS.COOKIE_POLICY]);
const canVisibleCookiePopup = computed(() => {
  if (!isAvailableCountry.value) return false;
  return showCookiePopup.value && siteData.value?.preview === false && compareShowCookieAndConsent.value
});

async function getParameters() {
  const countryCode = siteData.value?.countryCode;
  isAvailableCountry.value = ConsentCountries.includes(countryCode || '');
  showCookiePopup.value = isAvailableCountry.value;
}
function setStatus(granted: boolean) {
  if (!import.meta.client) return false;
  cookieConsent.value = granted ? 'true' : 'false';
  GTM.consent(granted);
  if (!GTM.options.id) GA4.consent(granted);
  // Init other integrations
  if (granted) {
    Integration.meta();
  }
}
// Init on SSR
getParameters();
</script>
