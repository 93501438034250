import { COOKIE } from "../cookie";

const logSyle =
  "background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;";

export const GTM = {
  options: {
    id: null as null | string,
    debug: false,
  },
  hasConsent: () => {
    const consent = getNamedCookie(COOKIE.COOKIE_CONSENT);
    return consent === 'true'
  },

  log(...args: any[]) {
    if (this.options.debug && import.meta.client) {
      console.log(`%c${(this as any).name || 'GTM'}`, logSyle, ...args);
    }
  },

  push(obj: Record<any, any>) {
    if (!this.options.id) return;
    if ((globalThis as any).dataLayer) {
      (globalThis as any).dataLayer.push(obj);
    }
    this.log('push', obj);
  },

  gtag(...args: any[]) {
    if (!this.options.id) return;
    if ((globalThis as any).gtag) {
      (globalThis as any).gtag(...args);
    }
    this.log.bind({ ...this, name: 'GA4' })('gtag', args)
  },

  consent(granted: boolean) {
    this.gtag('consent', 'update', {
      ad_storage: granted ? 'granted': 'denied',
      ad_user_data: granted ? 'granted': 'denied',
      ad_personalization: granted ? 'granted': 'denied',
      analytics_storage: granted ? 'granted': 'denied',
    })
  },

  init(payload: { id: string; domain?: string; debug?: boolean }) {
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };
    if (import.meta.server) return headScripts;
    if (this.options.id) return headScripts;
    if (!payload.id) {
      console.warn('GTM cannot be installed because there is no GTM ID!');
      return headScripts;
    }

    this.options.id = payload.id;
    this.options.debug = !!payload.debug;

    if (payload.debug) {
      this.log('init', payload.id);
      return headScripts;
    }

    const domain = payload.domain || 'https://www.googletagmanager.com';

    if (globalThis.document) {
      (globalThis as any).dataLayer = (globalThis as any).dataLayer || [];

      (function (w: any, d, s, l, i, dm) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s) as HTMLScriptElement,
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = dm + "/gtm.js?id=" + i + dl;
        j.onload = function(){ w.loadedIntegrations=w.loadedIntegrations||[];w.loadedIntegrations.push('GTM') };
        f.parentNode?.insertBefore(j, f);
      })(globalThis, globalThis.document, "script", "dataLayer", payload.id, domain);
    }

    headScripts.noscript = [
      {
        body: true,
        innerHTML: `<iframe src="${domain}/ns.html?id=${payload.id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
      }
    ]

    return headScripts;
  },

  initOnSSR(payload: { id: string; domain?: string; granted?: boolean, debug?: boolean }) {
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };

    if (!payload.id) {
      console.warn('GTM cannot be installed because there is no GTM ID!');
      return headScripts;
    }

    this.options.id = payload.id;
    this.options.debug = !!payload.debug;

    if (payload.debug) {
      this.log('init', payload.id);
      return headScripts;
    }

    let isDefatulGranted = 'denied';
    if (payload.granted || this.hasConsent()) isDefatulGranted = 'granted';
    const domain = payload.domain || 'https://www.googletagmanager.com';

    headScripts.script = [
      {
        innerHTML: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          ${
            payload.granted
              ? ''
              : `gtag('consent', 'default', {
          'ad_storage': '${isDefatulGranted}',
          'ad_user_data': '${isDefatulGranted}',
          'ad_personalization': '${isDefatulGranted}',
          'analytics_storage': '${isDefatulGranted}'
        });`
          }
        `
      },
      {
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        '${domain}/gtm.js?id='+i+dl;j.onload=function(){w.loadedIntegrations=w.loadedIntegrations||[];w.loadedIntegrations.push('GTM')};f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${this.options.id}');`
      },
    ];

    headScripts.noscript = [
      {
        body: true,
        innerHTML: `<iframe src="${domain}/ns.html?id=${this.options.id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
      }
    ]

    return headScripts;
  },
};
