import { parseQueryString } from "@/utils/queryString";
import { getNamedCookie, COOKIE } from "@/utils/cookie";
import { FacebookCAPI } from "@/services/facebookCapi";

const logSyle =
  "background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;";

export const Facebook = {
  options: {
    id: null as null | string | number,
    debug: false,
    pageSlug: '',
    countryCode: ''
  },

  log(...args: any[]) {
    if (this.options.debug && !import.meta.server) {
      console.log("%cFacebook Pixel", logSyle, ...args);
    }
  },
  
  event(...args: any[]) {
    if (!this.options.id) return
    (globalThis as any)?.fbq?.(...args);
    this.log("event", args);
  },

  track(...args: any[]) {
    if (!this.options.id) return
    this.event('track', ...args);
  },
  
  purchase(payload: {
    value: any;
    currency: any;
    orderID: any;
    eventID?: any;
  }) {
    this.track('Purchase', {
      value: payload.value,
      currency: payload.currency
    }, {
      eventID: payload.eventID,
      orderID: payload.orderID,
    })
  },

  prepareCAPIParams(siteUrl: string, event?: any) {
    const fbParams = {
      [COOKIE.FBCLICK_ID]: getNamedCookie(COOKIE.FBCLICK_ID, event) || '',
      [COOKIE.FBBROWSER_ID]: getNamedCookie(COOKIE.FBBROWSER_ID, event) || ''
    };

    const location = new URL(`${siteUrl.startsWith('http') ? siteUrl : `https://${siteUrl}`}`);
    const { fbclid } = parseQueryString(location?.search || '');

    if (!fbParams[COOKIE.FBCLICK_ID] && fbclid) {
      const subdomainIndex = 1;
      /* let subdomainIndex = location.host.split('.').length - 1;
      if (subdomainIndex > 2) subdomainIndex = 2;
      if (subdomainIndex < 0) subdomainIndex = 0; */
  
      fbParams[COOKIE.FBCLICK_ID] = `fb.${subdomainIndex}.${Date.now()}.${fbclid || ''}`;
    }

    return fbParams;
  },

  createCAPIClickID(params: { siteUrl?: string; value?: string; pageSlug: string; }) {
    const { value, pageSlug } = params;

    return {
      cookieName: COOKIE.FBCLICK_ID,
      exdays: generateExpireTime(new Date(new Date().setDate(new Date().getDate() + 90))).date, // 90 days
      value: value || '',
      path: pageSlug
    }
  },

  async sendCAPIInfo() {
    const win = (globalThis as any);

    setTimeout(() => {
      const params = Facebook.prepareCAPIParams(window.location.href);
      const hasAnyValue = !!Object.values(params).filter(Boolean).length;

      if (hasAnyValue) {
        const domain = globalThis?.location?.origin || '';
        FacebookCAPI.BASE_PATH = `${domain}/api/`;
    
        FacebookCAPI.send({
          fbp: params[COOKIE.FBBROWSER_ID]
        }, { hideErrorToast: true });
      }
    }, 1000);

    // Delete temp proxy object
    if (win?.proxy1) delete win.proxy1;

    // Destroy listener event
    win?.__EMITTER__?.off('fbLoad');
  },

  init(payload: { pixelId: number | string; debug?: boolean; pageSlug: string; countryCode: string }) {
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };

    if (import.meta.server) return headScripts;
    if (this.options.id) return headScripts;
    if (!payload.pixelId) {
      console.warn("Facebook Pixel cannot be installed because there is no Pixel ID!");
      return headScripts;
    }

    this.options.id = payload.pixelId;
    this.options.debug = !!payload.debug;
    this.options.pageSlug = payload.pageSlug;
    this.options.countryCode = payload.countryCode;

    if (this.options.debug) {
      this.event('init', this.options.id);
      this.track('PageView');
      return headScripts;
    }

    if (import.meta.client) {
      const win = globalThis as any;
      win?.__EMITTER__?.on('fbLoad', Facebook.sendCAPIInfo.bind(this));
    }

    if (globalThis.document) {
      (function(f: any,b: any, e: any, v: any) {
        if (f.fbq) return;
        const n: any = f.fbq = function(...args: any[]){ 
          return n.callMethod
            // eslint-disable-next-line prefer-spread
            ? n.callMethod.apply(n, args)
            : n.queue.push(args)
          };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        const t = b.createElement(e);
        t.async=!0;
        t.src=v;
        t.onload=function() {
          const win = globalThis as any;
          if (!win?.__EMITTER__) return false;
          win?.__EMITTER__.emit('fbLoad');
          if (win) {
            win.loadedIntegrations=win.loadedIntegrations||[];
            win.loadedIntegrations.push('FB');
          }
        }
        const s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
      this.event('init', this.options.id);
      this.track('PageView');
    }

    headScripts.noscript.push({
      body: true,
      innerHTML: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${this.options.id}&ev=PageView&noscript=1" />`
    })

    return headScripts
  },

  initOnSSR(payload: { pixelId: number | string; debug?: boolean, pageSlug: string; countryCode: string; }) {
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };

    if (!payload.pixelId) {
      console.warn("Facebook Pixel cannot be installed because there is no Pixel ID!");
      return headScripts;
    }

    this.options.id = payload.pixelId;
    this.options.debug = !!payload.debug;
    this.options.pageSlug = payload.pageSlug;
    this.options.countryCode = payload.countryCode;

    if (this.options.debug) {
      this.event('init', this.options.id);
      this.track('PageView');
      return headScripts;
    }

    if (import.meta.client) {
      const win = globalThis as any;
      win?.__EMITTER__?.on('fbLoad', Facebook.sendCAPIInfo.bind(this));
      /*
        Trigger fb load with proxy object.
        This is necessary because script load on SSR and till then, window.__EMITTER__ is not set to window object yet.
        When load this initOnSSR script, check proxy object on window.
        If object found, that means script loaded.
        After that, set object as true to trigger event by proxy handler below
      */
      if (win?.proxy1) win.proxy1.fbload = true; // Trigger listener
    }

    headScripts.script.push({
      innerHTML: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;t.onload=function(){
      f.loadedIntegrations=f.loadedIntegrations||[];
      f.loadedIntegrations.push('FB');
      window.proxy1=new Proxy({ fbload: false },{
      set(obj, prop, value){obj[prop]=value;
      if (window.__EMITTER__){window.__EMITTER__.emit('fbLoad', value)}return true;}})};
      s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${this.options.id}');
      fbq('track', 'PageView');`,
    });

    headScripts.noscript.push({
      body: true,
      innerHTML: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${this.options.id}&ev=PageView&noscript=1" />`
    })

    return headScripts;
  }
}

