export function registerCacheStorage(urls: string[]) {
  const CACHE_NAME = useRuntimeConfig().public.NUXT_CACHE_STORAGE_KEY;

  caches.open(CACHE_NAME).then((cache) => {
    return Promise.all(
      (Object.values(urls) || []).map(function(url) {
        return fetch(url, { mode: 'no-cors' })
          .then(response => cache.put(url, response));
      })
    );
  })
}